import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGodafoss360: allPagesCompilationYaml(
      filter: { code: { eq: "godafoss360EN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function Godafoss360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGodafoss360")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/godafoss.jpg"
      altitudeParDefaut={30}
      offsetNord={90}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          If you’ve ever wondered what it feels like to be under a very powerful
          aurora borealis, this 360° image taken on one of the most beautiful
          places on the planet in Godafoss in Iceland should bring you an
          answer.{" "}
        </p>
        <p>
          Look at the zenith: the aurora has taken on the form of a human face,
          don’t you think?
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          28 frames panoramic, Bushman Gobi panoramic head, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F2.2, ISO 6400. Individual
          exposures of 1 second.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
